.product-images-row {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 30px;
}

.product-image-container {
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.product-image-container img{
    width: 100px;
    height: 100px;
    object-fit: contain;

}

.upload-button {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 10px;
}

.upload-button h4 {
    color: #990820;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.checkbox-row {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.checkbox-label {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 0;
}