@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Barlow;
}

.next-gradient {
  background: radial-gradient(617.67% 351.39% at -12.56% -1.51%,
      #5e0a0a 0%,
      #ff003d 100%);
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

div::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.sideBarLogo {
  max-width: 150px;
}

.loginContainer {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.loginWindow {
  width: 890px;
  height: 870px;
  border-radius: 5px;
  align-self: center;
}

.borderRadiusLeft5 {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  height: 870px;
}

.borderRadiusRight5 {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  height: 870px;
}

.loginLogo {
  width: 300px;
  height: 300px;
}

.bg-lightGreen {
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

.bigger-text {
  font-size: 12pt;
}

.bg-lightGreen:disabled {
  font-weight: normal;
}

.gray-text {
  font-size: 14px;
  font-weight: 700;
  color: #B5B5C3;
}

.tabbed-link {
  padding-top: 10px;
  font-weight: 700;
  color: #990820;
  cursor: pointer;
}

.unselected-trigger {
  background-color: #E1E1E1;
}

.selected-trigger {
  background-color: #9AC984;
}

.white-background {
  background-color: white;
}

.gray-border {
  border-color: #E1E1E1;
  border-width: 1px;
}

.collapser-image {
  height: 25px;
  width: 25px;
  margin-left: 10px;
}

.pointer-none {
  pointer-events: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.position-rel {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 15px;
  top: 32px;
}